import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { LinkAbbr, Size, Space } from '@hse-design/react';
import { FigmaEmbed, ComponentLinks, P } from '../common';
import { colorBrandDark } from '@hse-design/tokens';
import { spacingSmall2X } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "linkabbr"
    }}>{`LinkAbbr`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { LinkAbbr } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=29703%3A37440'} storybook={'/?path=/story/typography-linkabbr--playground'} vueStorybook={'/?path=/story/typography-linkabbr--playground'} name={'LinkAbbr'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`LinkAbbr — это ссылка с подсказкой. LinkAbbr может использоваться в предложении или абзаце.`}</p>
    <p>{`По умолчанию в качестве элемента ссылки используется элемент `}<inlineCode parentName="p">{`a`}</inlineCode>{`. Его можно изменить, передав в проп `}<inlineCode parentName="p">{`component`}</inlineCode>{` нужный элемент`}</p>
    <p>{`Для задания содержимого подсказки используйте проп `}<inlineCode parentName="p">{`tooltipContent`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'<LinkAbbr tooltipContent=\"Высшая школа экономики\">ВШЭ</LinkAbbr>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      LinkAbbr,
      Size,
      Space,
      FigmaEmbed,
      ComponentLinks,
      P,
      colorBrandDark,
      spacingSmall2X,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LinkAbbr tooltipContent='Высшая школа экономики' mdxType="LinkAbbr">
    ВШЭ
  </LinkAbbr>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=30114:40175'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=30114:40205'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={LinkAbbr} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`component`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      